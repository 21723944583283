import { setCookie } from '../../utils/cookie_manager';

(function () {
  function setCountryCookie() {
    if (!$.cookie('x-country')) {
      try {
        let req = new XMLHttpRequest();
        let country = req.getResponseHeader('x-country');
        setCookie('x-country', country, 7);
      } catch (e) {}
    }
  }
  $(function () {
    setCountryCookie();
  });
})();
