import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { initializeUtagEvents } from './sort_by.analytics'

/* eslint-disable */

dayjs.extend(customParseFormat)

(function ($) {
  'use strict';

  if (typeof IB === 'undefined') {
    window.IB = {};
  } else if (IB.sort_by !== undefined) {
    return;
  }

  let $doc = $(document);
  let $wrapper;
  let order_by;

  initializeEvents();

  Array.prototype.allValuesSame = function () {
    if (this.length == 1) return true;
    for (let i = 1; i < this.length; i++) {
      if (this[i] !== this[0]) {return false;}
    }
    return true;
  };

  // Ascendant order
  function sort_by(new_order, wrapper) {
    $wrapper = wrapper;
    if (new_order === order_by) {
      return false;
    } else {
      order_by = new_order;
    }

    let $sortables = $wrapper.find('[data-sortable]').sort(function (a, b) {
      let a_val = $(a).data(order_by);
      let b_val = $(b).data(order_by);
      let date_format = $('html').attr('data-date-format');

      switch (order_by) {
      case 'category':
        return a_val > b_val ? 1 : -1;
      case 'created':
      case 'date':
      case 'expiration':
      case 'pre-date':
        a_val = dayjs(a_val, date_format);
        b_val = dayjs(b_val, date_format);
        return dayjs(a_val).isAfter(b_val) ? 1 : -1;
      case 'price':
        return parseFloat(a_val) > parseFloat(b_val) ? 1 : -1;
      default:
        return a_val > b_val ? 1 : -1;
      }
    });

    $wrapper.html($sortables);
  }

  // Descendant order
  function sort_by_top(new_order, wrapper) {
    $wrapper = wrapper;
    order_by = 'active';
    if (new_order === order_by) {
      return false;
    } else {
      order_by = new_order;
    }

    let $sortables = $wrapper.find('[data-sortable]').sort(function (a, b) {
      let a_val = $(a).data(order_by);
      let b_val = $(b).data(order_by);
      let date_format = $('html').attr('data-date-format');

      switch (order_by) {
      case 'category':
        return a_val < b_val ? 1 : -1;
      case 'created':
      case 'date':
      case 'expiration':
      case 'pre-date':
        a_val = dayjs(a_val, date_format);
        b_val = dayjs(b_val, date_format);
        return dayjs(a_val).isBefore(b_val) ? 1 : -1;
      case 'price':
        return parseFloat(a_val) < parseFloat(b_val) ? 1 : -1;
      default:
        return a_val < b_val ? 1 : -1;
      }
    });

    $wrapper.html($sortables);
    order_by = 'active';
  }

  function action_click_sort($el, $wrapper) {
    let val = [];
    let sort_property = $el.attr('data-sort');

    $wrapper.find('[data-' + sort_property + ']').map(function () {
      val.push($(this).data(sort_property));
    });

    if (!$el.hasClass('not-reversible')) {
      $el.closest('ul').find('li a').removeClass('active');
      $el.addClass('active');
      if (!val.allValuesSame()) {
        sort_by(sort_property, $wrapper);
      }
      if ($el.hasClass('reversible')) {
        $('[data-sort]').removeClass('bottom');
        $('[data-sort]').removeClass('top');
        $el.addClass('bottom');
      }
    } else if ($el.hasClass('bottom')) {
      if (!$el.hasClass('not-reversible')) {
        $el.removeClass('bottom');
        $el.addClass('top');
      }
      if (!val.allValuesSame()) {
        sort_by_top(sort_property, $wrapper);
      }
    } else if ($el.hasClass('top')) {
      if (!$el.hasClass('not-reversible')) {
        $el.removeClass('top');
        $el.addClass('bottom');
      }
      if (!val.allValuesSame()) {
        sort_by(sort_property, $wrapper);
      }
    }

    if ($el.hasClass('selectable')) {
      const dropdownId = $el.closest('.js-sort-links').attr('id');
      const dropdownTrigger = document.querySelector(`.js-d-link[href="#${dropdownId}"]`);

      $('[data-sort]').removeClass('active');
      $el.addClass('active');

      dropdownTrigger && dropdownTrigger.classList.remove('active');

      let text = $el.text();
      $el.parents('.drop-down-links').hide().prev('a')
        .text(text);
    }

    let adverts = $wrapper.data('adverts');
    let adverts_index = 0;
    if ((adverts !== undefined) && (adverts !== null) && (adverts instanceof Array) && (adverts.length > 0)) {
      $.each($wrapper.find('.card'), function (index, element) {
        if ((index + 1) % 5 == 0) {
          $(element).after('<div class="card-banner js-myibe-hide"><p class="t-fb-label">' + adverts[adverts_index % adverts.length] + '</p></div>');
          adverts_index = adverts_index + 1;
        }
      });
    }

    // reinit tooltips after filtering in hotel list
    if ($('.hotel-list [data-tooltip]').length) {
      $('.hotel-list [data-tooltip]').each(function (index, element) {
        if (IB !== undefined && IB.tooltip !== undefined) {
          IB.tooltip.init($(element));
        }
      });
    }
  }

  function initializeEvents() {
    const utagSortLinks = document.querySelectorAll('.utag-sort-links li a')

    if (!utagSortLinks || utagSortLinks.length === 0) {
      return
    }

    for (let i = 0; i < utagSortLinks.length; i++) {
      const element = utagSortLinks[i]

      element.addEventListener('click', () => {
        initializeUtagEvents(element)
      })
    }
  }

  $doc.on('click', '[data-sort]', function (e) {
    e.preventDefault();

    let $el = $(this);

    // We send the utag event before sorting
    initializeUtagEvents($el.data('sort'))

    let data = $el.data('sort-wrapper');
    if (Array.isArray(data)) {
      for (let index = 0; index < data.length; index++) {
        order_by = undefined;
        action_click_sort($el, $(data[index]));
      }
    } else {
      action_click_sort($el, $(data));
    }
  });

  $doc.ready(function () {
    // Ponemos las reservas activas primeras al cargar la pagina en Mi Iberostar
    if ($('.reserve-index-list').length > 0) {
      $wrapper = $('.reserve-index-list');
      sort_by('active', $wrapper);
    }

    if ($('.pre-reservations-index-list').length > 0) {
      $wrapper = $('.pre-reservations-index-list');
      sort_by('active', $wrapper);
    }

    if ($('.offers-index-list .js-tabs-content').length > 0) {
      $wrapper = $('.offers-index-list .js-tabs-content');
      sort_by('created', $wrapper);
    }

    if ($('.hotels-info-with-filtering.pertimm').length > 0) {
      $wrapper = $('.hotel-list');
      sort_by('price', $wrapper);
    }

    if ($('.my-iberostar-page .results-order-by').length > 0) {
      $wrapper = $('.hotel-list');
      sort_by('price', $wrapper);
    }

    if ($('.wrapper-iberostar-gifts .results-order-by').length > 0) {
      $wrapper = $('.upselling-list');
      sort_by('points-num', $wrapper);
    }
  });



  IB.sort_by = sort_by;
}(jQuery));
