import Modal from '../../shared/modal/modal'

const MODAL_BADGE_DATA_ATTR_NAME = 'modalBadgeId'
const MODAL_ALL_BADGES_DATA_ATTR_NAME = 'modalAllBadgesId'

function init() {
  document.addEventListener('click', function (e) {
    const element = e.target
    const hasBadgeId = MODAL_BADGE_DATA_ATTR_NAME in element.dataset
    const hasAllBadgesId = MODAL_ALL_BADGES_DATA_ATTR_NAME in element.dataset

    if (!element || (!hasBadgeId && !hasAllBadgesId)) return

    const dataAttr = hasBadgeId ? MODAL_BADGE_DATA_ATTR_NAME : MODAL_ALL_BADGES_DATA_ATTR_NAME

    const modalId = element.dataset[dataAttr]

    if (!modalId) return

    Modal.show(modalId, {
      onShow: () => {
        ScrollLock.on()
      },
      onClose: () => {
        ScrollLock.off()
      },
      awaitCloseAnimation: true,
    })
  })
}

init()
