//------------------------------- Hide banners  ---------------------------------------------//

function hideAllBanners({ root }) {
  root.querySelectorAll('.ss-slide').forEach(function (item) {
    item.classList.add('is-hidden')
  })
}

//------------------------------- Organize banners  ---------------------------------------------//

function organize_banners({ root, visit, max_elements, item_class = '' }) {
  const visit_config = {
    'first-visit': '1stv',
    'second-visit': '2ndv',
  }

  // Custom class logic
  const custom_item_class = `.ss-slide${item_class}`

  visit.forEach(key => {
    if (!visit_config[key]) {
      console.log('Parameter not valid.  \nValid parameters: first-visit, second-visit. Example show(["first_visit"]) or show(["first_visit", "second_visit"])')
    }
  })

  const banners = root.querySelectorAll(custom_item_class)

  banners.forEach(function (item) {
    // Control element type
    visit.forEach(key => {
      let banner_type = visit_config[key]
      const banner_key = item.querySelector('[data-banner-segment-key]')

      if (!banner_key || !banner_key.dataset.bannerSegmentKey.length) {
        banner_type = 'always'
      }

      if (banner_type === 'always' || banner_type === banner_key.dataset.bannerSegmentKey) {
        item.classList.remove('is-hidden')
      }
    })
  })

  // Manages maxnumber of visible items
  const notHiddenBannerSelector = `${custom_item_class}:not(.is-hidden)`
  let notHiddenBanners = root.querySelectorAll(notHiddenBannerSelector)
  let totalBanners = notHiddenBanners.length

  if (!max_elements) {
    return
  }

  while (totalBanners > max_elements) {
    const wildCardBanners = root.querySelectorAll(`${notHiddenBannerSelector}.wildcard`)
    notHiddenBanners = root.querySelectorAll(notHiddenBannerSelector)
    // Removes useless banners until the total is adjusted to the maximum allowed
    if (wildCardBanners.length) {
      wildCardBanners[wildCardBanners.length - 1].classList.add('is-hidden')
    } else {
      notHiddenBanners[notHiddenBanners.length - 1].classList.add('is-hidden')
    }

    totalBanners--
  }
}

//------------------------------- Show banners  ---------------------------------------------//

function show(visit_status) {
  if (!visit_status || !visit_status.length) {
    console.log('You must specify a range of parameters. Example show(["first-visit"]) or show(["first-visit", "second-visit"]) ')
    return
  }

  // Case A: Special offers banner
  if (document.querySelector('.special-offers-banner')) {
    const params = {
      root: document.querySelector('.special-offers-banner'),
      visit: visit_status,
    }

    hideAllBanners(params)
    organize_banners(params)
  }

  // Case B: Banners Grid
  else if (document.querySelector('.banners-grid-layer .banners-wrapper')) {
    const params = {
      root: document.querySelector('.banners-grid-layer .banners-wrapper'),
      visit: visit_status,
      max_elements: 6,
    }
    hideAllBanners(params)
    organize_banners(params)
  }
}

//------------------------------------------------------------------------------------//

if (IB === undefined) IB = {}
IB.currentDevice = document.getElementsByTagName('html')[0].dataset.deviceType

// If Home set first visit and no segmentation by default
if (document.querySelector('[data-page-type="home"]')) {
  show(['first-visit'])
}

IB.banners = {
  show,
}
