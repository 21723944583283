import './toggle.scss';

$(function () {
  'use strict';

  let $element = $('.js-toggle');
  let $doc = $(document);

  $doc.on('click', $element.selector, function (e) {
    let $target = $(e.target).closest('.js-toggle');
    $target.toggleClass('active');
    $target.trigger('change');
  });
});
