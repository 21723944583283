import { isDebugConsoleEnabled } from './debug'
import { getLang, getLangPrefix } from './i18n'

/**
 * Retrieves real market content based on the provided parameters.
 * @param {Object} options - The options object.
 * @param {string} options.contentName - The name of the content.
 * @param {string} options.blockId - The ID of the block.
 * @param {string} options.constrainedViewId - The ID of the constrained view.
 * @param {string} options.additionalParams - If provided, it will be appended to the query string.
 * @returns {Promise} A promise that resolves to the retrieved data or rejects with an error.
 * @example
 * getRealMarketContent({ contentName: 'menu_offers' }).then(response => {
 *   const container = document.querySelector('.js-container')
 *   container.outerHTML = response.html_replacement
 * })
 */
const getRealMarketContent = ({ contentName, blockId, constrainedViewId, additionalParams = '' } = {}) => {
  const showErrorMessage = errorMessage => {
    // show console messages only in debug mode
    if (isDebugConsoleEnabled && errorMessage) {
      // eslint-disable-next-line no-console
      console.warn(`[Update with real market]: ${errorMessage}`)
    }
  }

  // Check if real market is already applied (data-is-real-market present in body)
  if ('isRealMarket' in document.body.dataset) {
    showErrorMessage('real market is already applied')
    return Promise.reject('alreadyApplied')
  }

  // Check presence of required params
  if (
    (!contentName && !blockId) ||
    (contentName && blockId) ||
    (blockId && !constrainedViewId) ||
    (!blockId && constrainedViewId)
  ) {
    // show console messages only in debug mode
    let msg = ''
    if (!contentName && !blockId) {
      msg = 'request could not be performed because neither contentName nor blockId has been provided'
    } else if (contentName && blockId) {
      msg = 'request could not be performed because both contentName and blockId have been provided'
    } else if (blockId && !constrainedViewId) {
      msg = 'request could not be performed because blockId has been provided but not constrainedViewId'
    } else if (!blockId && constrainedViewId) {
      msg = 'request could not be performed because constrainedViewId has been provided but not blockId'
    }

    showErrorMessage(msg)
    return Promise.reject(new Error(msg))
  }

  const currentLang = getLang()
  const urlPrefix = getLangPrefix().replace('/', '')

  const urlParams = new URLSearchParams(window.location.search)
  const aac = urlParams.get('AAC') || urlParams.get('aac')

  if (aac) {
    additionalParams = additionalParams ? `${additionalParams}&AAC=${aac}` : `AAC=${aac}`
  }

  const urlBlockParams = contentName
    ? `content_name=${contentName}`
    : `block_id=${blockId}&constrained_view_id=${constrainedViewId}`
  const otherParams = additionalParams ? `&${additionalParams}` : ''

  const getMarketContentUrl = `/${
    urlPrefix ? `${urlPrefix}/` : ''
  }get_market_content?lang=${currentLang}&${urlBlockParams}${otherParams}`

  return fetch(getMarketContentUrl)
    .then(response => {
      if (!response.ok) {
        throw new Error(`[Update with real market] Error in API response: ${response.status} ${response.statusText}`)
      }
      return response.json()
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const dispatchRealMarketContentUpdatedEvent = ({ blockSelector, blockName } = {}) => {
  // Check presence of required params
  if (!blockSelector || !blockName) {
    console.error(
      '[Update with real market]: event could not be dispatched because blockSelector or blockName has not been provided'
    )
    return
  }

  const event = new CustomEvent(`html-updated:${blockName}`, {
    detail: blockSelector,
  })

  document.dispatchEvent(event)
}

export default getRealMarketContent
