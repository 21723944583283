import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

$(function () {
  let dates = document.getElementsByClassName('input-date');
  let date_format = $('html').data('date-format') === 'MM/DD/YYYY' ? 'usa' : 'eu';
  let p_day = date_format === 'usa' ? 1 : 0;
  let p_month = date_format === 'usa' ? 0 : 1;

  FORMZ.required_fields.init($(dates));

  $(dates).closest('.fz-col').css('max-width', $(dates).closest('.fz-col').outerWidth() + 1);

  function checkValue(str, max) {
    let num2 = null;
    if (str.charAt(0) !== '0' || str == '00') {
      let num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) {
        num2 = +num.toString()[1];
        num = +num.toString()[0];
        max = num - 1;
      }
      str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      // console.log(num2);
    }
    return { 'str': str, 'num2': num2 };
  }

  window.Parsley
    .addValidator('validateTest', {
      validateString: function (value) {
        let values = value.split('/');
        return +values[2] > 1900 && +values[2] < dayjs().format('YYYY');
      },
      messages: {
        es: 'El año no puede ser posterior al actual ni anterior a 1900',
        en: 'The year cannot be after the current year or before 1900',
        de: 'Das Jahr darf weder nach dem Aktuellen noch vor 1900 liegen',
        fr: 'L’année ne peut pas être postérieure à l’année actuelle ni antérieure à 1900',
        it: 'L’anno non può essere successivo all’attuale né anteriore al 1900',
        pt: 'O ano não pode ser posterior ao ano atual nem anterior a 1900',
        ru: 'Год не может быть позднее текущего или ранее 1900'
      }
    });

  function eventInput(e, ths) {
    if (e.data === null) return false;

    ths.type = 'text';
    let input = ths.value;


    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);

    let values = input.split('/').map(function (v) {
      return v.replace(/\D/g, '');
    });

    let v = null;

    if (values[p_month]) {
      v = checkValue(values[p_month], 12);
      values[p_month] = v.str;
    }
    if (values[p_day]) {
      v = checkValue(values[p_day], 31);
      values[p_day] = v.str;
    }

    let output = values.map(function (v, i) {
      return v.length == 2 && i < 2 ? v + '/' : v;
    });
    ths.value = output.join('').substr(0, 10);

    if (v.num2 != null) {
      $(ths).val(ths.value + v.num2);
      eventInput(e, ths);
    }
  }

  let date;
  for (let i = 0; i < dates.length; i++) {
    date = dates[i];

    $(date).attr('placeholder', $('html').data('date-format'));

    date.addEventListener('input', function (e) {
      eventInput(e, this);
    });
    date.addEventListener('blur', function (e) {
      this.type = 'text';
      let input = this.value;
      let values = input.split('/').map(function (v, i) {
        return v.replace(/\D/g, '');
      });
      let output = '';

      if (values.length == 3) {
        let year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
        let month = parseInt(values[p_month]) - 1;
        let day = parseInt(values[p_day]);
        let d = new Date(year, month, day);

        if (!isNaN(d)) {
          // document.getElementById('result').innerText = d.toString();
          dates = date_format === 'usa' ? [d.getMonth() + 1, d.getDate(), d.getFullYear()] : [d.getDate(), d.getMonth() + 1, d.getFullYear()];
          output = dates.map(function (v) {
            v = v.toString();
            return v.length == 1 ? '0' + v : v;
          }).join('/');
        }
      }
      this.value = output;
    });
    console.log(i);
  }
});
