let is_checked = false;

function _init() {
  is_checked = true;
  let parameter = _getParameter('embeddedIn');

  if(parameter && ['android','ios'].includes(parameter)) {
    try{
      localStorage.setItem('in-app', parameter);
    } catch (e) {
      console.log(e);
    };
  }

  _activeResetInApp();
  _manageInAppClass();
}


function _activeResetInApp() {
  let parameter = _getParameter('reset_embedded');
  if (parameter && parameter == 'true' && is_checked) {
    try{
      localStorage.removeItem('in-app');
    } catch (e) {
      console.log(e);
    };
  }
}

function _getParameter(key) {
  let url_params = window.location.search ? window.location.search.toLowerCase() : '';
  let searchParams = new URLSearchParams(url_params);
  let parameter = searchParams.get(key.toLowerCase());

  return parameter;
}

function _getIsEmbedded() {
  if (!is_checked) _init();

  return ['android','ios'].includes(localStorage.getItem('in-app'));
}

function _manageInAppClass() {
  let condition_in_app = ['android','ios'].includes(localStorage.getItem('in-app'));
  condition_in_app ? 
    document.body.classList.add('in-app', 'app-'+localStorage.getItem('in-app')) 
    : document.body.classList.remove('in-app', 'app-ios', 'app-android');
}


const Webview = {
  init: _init,
  getIsEmbedded: _getIsEmbedded 
}

Webview.init();

export default Webview;
