import '../../vendors/template_engine/template_engine'

import Modal from '../../shared/modal/modal'

function activateShareBtn2() {
  const $lightbox = $('#shared-block')

  $(document).on('click', '.js-share-base', function (e) {
    const $this = $(this)
    e.preventDefault()
    $('body').addClass('offer-shareable')

    const $share_facebook_element = $lightbox.find('.share-facebook')
    const $share_twitter_element = $lightbox.find('.share-twitter')
    const $form_search_title = $lightbox.find('.form-search-title')
    const $share_list_mail = $lightbox.find('.share-list .mail')
    const $share_list_whatsapp = $lightbox.find('.share-list .whatsapp')
    const $share_list_sms = $lightbox.find('.share-list .sms')
    Modal.show('shared-block', {
      onShow: () => {
        const $mail_to_data = `mailto:?body=${$this.data('hotel-name')} ${$this.data('description')}%0A${$this.data('url')}`

        $share_facebook_element.attr('data-url', $this.data('url'))
        $share_facebook_element.attr('data-quote', `${$this.data('hotel-name')} ${$this.data('description')}`)
        $share_twitter_element.attr('data-url', $this.data('url'))
        $share_twitter_element.attr('data-quote', `${$this.data('hotel-name')} ${$this.data('description')}`)
        $lightbox.find('.share-email').attr('href', $mail_to_data)
        $form_search_title.addClass('hidden')
        $(
          `<p class="form-title form-search-title-base t-highlight mb-1">${$this.data('lightbox-title')}</p>`
        ).insertAfter($form_search_title)

        $share_list_mail.attr('href', $mail_to_data)
        $share_list_whatsapp.attr(
          'href',
          `whatsapp://send?text=${$this.data('hotel-name')} ${$this.data('description')}\n${$this.data('url')}`
        )

        const chrt = IB.lastSearchesCards.getMobileOperatingSystem() === 'ios' ? '&' : '?'

        $share_list_sms.attr(
          'href',
          `sms:///${chrt}body=${$this.data('hotel-name')} ${$this.data('description')}\n${$this.data('url')}`
        )
        $lightbox
          .find('textarea[name=shared-subject]')
          .text(`${$this.data('hotel-name')} ${$this.data('description')} ${$this.data('url')}`)
        $lightbox.find('#url-hidden-input').val($this.data('url'))
        $lightbox.find('.copy button').removeClass('selected').attr('data-clipboard-text', $this.data('url'))

        drawOffer2($('.shared-block'), $this)

        $('.shared-block').addClass('base').find('.social-share-list').addClass('base')
      },
      onClose: () => {},
      awaitCloseAnimation: true,
    })
  })
}

function drawOffer2($shared_block, $el) {
  const $desc_cnt = $shared_block.find('.desc-cnt')
  const $desc = $desc_cnt.find('.desc-card')
  const template = $('#modal-desc-card-template').html()

  $desc.remove()

  const template_options = {
    image: $el.data('image'),
    title: $el.data('hotel-name'),
    description: $el.attr('data-description'),
  }
  $desc_cnt.append(TemplateEngine(template, template_options))
  $desc_cnt.find('.desc.desc-share').remove()
  $desc_cnt.find('.desc-title:not(.static)').remove()
}

$(function () {
  activateShareBtn2()

  const getWindowOptions = function () {
    return ['resizable,scrollbars,status', 'height=350', 'width=350'].join()
  }

  $(document)
    .on('click', '.shared-block.base .share.share-facebook', function (e) {
      e.preventDefault()

      $.fancybox.close()

      const $this = $(this)
      const url = $this.data('url')
      const title = encodeURIComponent($this.data('quote'))

      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`,
        'facebookwindow',
        getWindowOptions()
      )

      return false
    })
    .on('click', '.shared-block.base .share.share-twitter', function (e) {
      e.preventDefault()

      const $this = $(this)
      const url = $this.data('url')
      const title = encodeURIComponent($this.data('quote'))

      window.open(`https://twitter.com/intent/tweet?text=${title}&url=${url}`, 'ShareOnTwitter', getWindowOptions())
    })
})
