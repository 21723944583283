/* eslint-disable no-console */
import { readCookie, setCookie } from '../../utils/cookie_manager'

const INTERVAL_DURATION = 500
let numberOfIntervals = 6 // 3 seconds max

function checkLanguageCookie() {
  const languageSelector = document.querySelector('.language-selector .drop-down-links')
  const lang = document.documentElement.getAttribute('data-js-lang')

  if (!readCookie('locale')) {
    setCookie('locale', lang, 7)
  }
  // Set Cookie language value in Locale Cookie value when we go to booking.iberostar, change languange and return to home
  const cookieLanguage = readCookie('language')

  if (cookieLanguage && cookieLanguage !== lang) {
    numberOfIntervals = false
    languageSelector.querySelectorAll('.drop-down-item').forEach(item => {
      const link = item.querySelector('[data-locale]')
      const item_locale = link.dataset.locale
      if (item_locale === cookieLanguage) {
        // Remove cookie language
        IB.cookieManager.deleteCookies(['language'])
        setCookie('language', lang, -1)
        link.click()
      }
    })
  }
}

const languageCookieInterval = setInterval(() => {
  if (numberOfIntervals) {
    numberOfIntervals--
    checkLanguageCookie()
  } else {
    clearInterval(languageCookieInterval)
  }
}, INTERVAL_DURATION)
