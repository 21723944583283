$(function () {
  'use strict';
  let ARIA_DROPDOWN = 'aria-expanded';
  let ARIA_HIDDEN = 'aria-hidden';

  //
  // Change aria-expanded value of accordions/dropdowns triggers
  //
  $(document).on('click', $('[' + ARIA_DROPDOWN + ']').selector, function () {
    let $this = $(this);
    let is_expanded = JSON.parse($this.attr(ARIA_DROPDOWN)); // safe cast to Boolean

    $this.attr(ARIA_DROPDOWN, !is_expanded);
  });


  //
  // Sets aria-hidden in the page wrapper div after fancybox state (open/closed)
  //
  (function setAriaHiddenAfterFancyboxState() {
    let body_node = $('body')[0];
    let $page_wrapper = $('.page-wrapper');
    let is_fb_visible;
    let fancybox_observer;

    fancybox_observer = new MutationObserver(function () {
      is_fb_visible = body_node.contains($('.fancybox-is-open')[0]);
      $page_wrapper.attr(ARIA_HIDDEN, is_fb_visible);
    });

    fancybox_observer.observe(body_node, {
      attributes:    false,
      childList:     true,
      characterData: false,
      subtree:       false
    });
  })();
});
