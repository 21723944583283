let $nav_wrapper
let $internal_nav
let $nav_list
const menu_els = []
let $wrapperList

function init($wrapper) {
  if (typeof $wrapper === 'undefined') {
    $wrapper = $nav_wrapper
  }
  // Create each menu cached object and trigger fitToWidth
  if (IB.currentDevice === 'mobile') {
    if (IB.currentPageType.get() === 'page') {
      const internalNav = document.querySelector('.js-internal-nav-cnt')
      internalNav.classList.add('hidden')
    }

    const wrapper = document.querySelectorAll('.internal-nav')
    const navCnt = document.querySelector('.js-internal-nav-cnt')

    const menuItems = Array.from(document.querySelectorAll('.js-menu-item-in-bar'))

    menuItems?.forEach(menuItem => {
      menuItem.addEventListener('click', function () {
        const scrollLeft = menuItem.offsetLeft + menuItem.offsetWidth / 2 - navCnt?.offsetWidth / 2
        navCnt?.scrollTo({ left: scrollLeft })
      })
    })
    window.addEventListener('load', function () {
      wrapper.forEach(nav => {
        const activeItem = document.querySelector('.js-menu-item-in-bar.active')
        const scrollLeft = activeItem?.offsetLeft + activeItem?.offsetWidth / 2 - navCnt?.offsetWidth / 2
        navCnt?.scrollTo({ left: scrollLeft })
      })
    })
  } else {
    $wrapper.each(function () {
      const $el = $(this)
      const $list = $el.find('.tableize')
      if ($list.length) {
        createMenuObj($el, $list)
      }
    })
    fitToWidth()
  }
}

function createMenuObj($el, $list) {
  const $more = $el.find('.more')
  let $current = $el.find('.current')

  // If current not exist, get first li
  if ($current.length === 0) {
    $current = $el.find('li:first-child')
  }

  // Remove tableize class to get size elements
  $list.removeClass('tableize')

  // Get children elements and cache width
  const $children = $el
    .find('.menu_item')
    .not('.current')
    .map(function () {
      return cacheElWidth($(this))
    })
    .toArray()

  menu_els.push({
    el: $el,
    children: $children,
    more: cacheElWidth($more),
    current: cacheElWidth($current),
  })

  $list.addClass('tableize')
}

function cacheElWidth($el) {
  $el.data('width', $el.outerWidth())
  return $el
}

function fitToWidth() {
  if (menu_els.length === 0) return false
  for (let i = 0; i < menu_els.length; i++) {
    processMenu(menu_els[i])
  }
  $internal_nav.find('ul').addClass('auto-width')
}

function processMenu(m) {
  const max_width = m.el.outerWidth() - 50
  const $links_bucket = m.more.find('ul')
  const $bucket_els = $links_bucket.find('li')
  const children = m.children.slice(0) // Clone array
  let children_width = m.more.data('width') + m.current.data('width')

  children.map(function (child) {
    children_width += child.data('width')
  })

  // Si los elementos entran en el menú ocultamos el botón "ver mas"
  if (children_width <= max_width) {
    m.more.hide()
    return false
  }
  m.more.show()

  if ($bucket_els.length > 0) {
    $bucket_els.detach().insertBefore(m.more)
  }

  // Si no entran, mostramos el more, sacamos el último elemento y lo añadimos al desplegable
  while (children_width > max_width) {
    const $current = children.pop()
    if (typeof $current === 'undefined') return false
    children_width -= $current.data('width')
    $current.detach().prependTo($links_bucket)
  }
}

function fixSubmenuMargin() {
  $nav_list.each(function () {
    const $el = $(this)
    const $submenu = $el.find('.submenu:visible')

    if ($submenu.length > 0) {
      $el.css('margin-bottom', $submenu.outerHeight())
    }
  })
}

// Debounced resize to improve performance
function on_resize_window(c, t) {
  $(window).on('resize', function () {
    clearTimeout(t)
    t = setTimeout(c, 250)
    return c
  })
}

window.IB.internalNav = {
  init,
  elements: menu_els,
}

$(function () {
  $nav_wrapper = $('.internal-nav, .internal-submenu')
  $internal_nav = $('.internal-nav')
  $wrapperList = $('.wrapper')

  if ($nav_wrapper.length) {
    $nav_list = $nav_wrapper.find('ul:visible')
    fixSubmenuMargin()

    // Tanto en window load como en resize y orientation change inicializamos el módulo
    init()
    on_resize_window(function () {
      fitToWidth()
    })
    navWithDropdown()
  }
})

/**
 * PY04000-14 Submenu in specialty pages
 * Inspire by destination-breadcrumbs
 */
function calculateChildrenPosition($target) {
  const $submenuWrapper = $target.closest('.has-children').find('.wrapper')
  const menuItemPos = $target.position()
  $submenuWrapper.css({
    top: menuItemPos.top + $target.outerHeight(),
    left: menuItemPos.left,
  })
}

function closeCurrentActive() {
  const $prev_active = $('.js-internal-nav-dropdown.active')

  if ($prev_active.length) {
    $prev_active.removeClass('active')
    $prev_active.closest('.has-children').find('.js-drop-down-links').slideToggle('fast')
  }
}

function navWithDropdown() {
  $('.js-internal-nav-wrap').each(function () {
    const $wrapper = $(this)
    $wrapper.width($wrapper.siblings('.d-link').width())
  })

  $(document).on('click', '.js-internal-nav-dropdown', function (e) {
    e.preventDefault()
    const $target = $(this)
    calculateChildrenPosition($target)
    const $wrapperList = $target.closest('.has-children').find('.js-drop-down-links')

    if (!$target.hasClass('active')) {
      closeCurrentActive()
    }

    $wrapperList.slideToggle('fast')
    $target.toggleClass('active')
  })

  $('.js-internal-nav-scroll').on('scroll', function () {
    const $active_dropdowns = $(this).find('.d-link.active')
    if ($active_dropdowns.length) {
      calculateChildrenPosition($active_dropdowns)
    }
  })
}
