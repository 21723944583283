import { sendUtagEvent } from '../../core/analytics/utag_events'

export function initializeUtagEvents(elementLabel) {
  sendUtagEvent({
    data: {
      event_name: 'sort_by',
      event_cat: 'destinations',
      event_act: 'sort by',
      event_lbl: elementLabel,
      event_purpose: 'inspiration',
      event_structure: 'body',
    },
  })
}
