import _each from 'lodash/each';

(function ($) {
  // Shows the skeleton hidding the specified elems using the specified color
  // @params:
  //    - selectors [string]: selectors separated by comma. ex: '.selector1, .selector2, .father .child'
  //    - bgColor [string]: color used to hide the elements. ex '#fff'
  //
  // Example:
  //    IB.skeleton.show('.selector1, .selector2, .father .child', '#000')

  function showSkeleton(selectors, bgColor) {
    let elems = selectors.split(',');
    bgColor = bgColor !== null && bgColor !== undefined ? bgColor : '#FAFAFA';
    _each(elems, function (elem) {
      let $elem = $(elem);
      if ($elem.length) {
        $elem.addClass('b-skeleton').append('<div class="skeleton-inner" style="background-color:' + bgColor + '"></div>');
      }
    });
  }

  // Hides all the skeletons and shows the hidden content
  function hideSkeleton() {
    $('.b-skeleton').removeClass('b-skeleton');
    $('.skeleton-inner').remove();
  }

  // Hides the specified skeletons
  function hideSelectedSkeleton(selectors) {
    $('.b-skeleton').removeClass('b-skeleton');
    $('.skeleton-inner').remove();
    let elems = selectors.split(',');
    _each(elems, function (elem) {
      let $elem = $(elem);
      if ($elem.length) {
        $elem.removeClass('b-skeleton').find('.skeleton-inner').remove();
      }
    });
  }

  window.IB.skeleton = {
    show:         showSkeleton,
    hide:         hideSkeleton,
    hideselected: hideSelectedSkeleton
  };
})(jQuery);
