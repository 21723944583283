import { getParameterByName } from '../../../utils/get_parameter_by_name';

function persistSessionStorage(current_market_id){
  // Not working in Safari, in Private Browsing Mode
  try{
    sessionStorage.removeItem('fastbooking_data');
    sessionStorage.setItem("market-id", current_market_id);
  } catch (e) {
    console.log("Your web browser does not support storing settings locally. In Safari, the most common cause of this is using Private Browsing Mode. Some settings may not save or some features may not work properly for you.");
  }
}


/* Return true If change data attribute market-id or include simulate_market param reload fastbooking_data*/
function marketChanged(old_market_id, callback){
  /*Get parameter from url*/
  var parameter = getParameterByName("simulate_market");
  var current_market_id = $("html").data("market-id");

  if ([null,"","undefined","null"].indexOf(parameter) === -1 || String(current_market_id) != String(old_market_id)){
    callback(current_market_id);
  }
}


$( document ).ready(function() {
  /*Get old parameter from session storage */
  var old_market_id = sessionStorage.getItem("market-id");
  marketChanged(old_market_id, function(data){
    persistSessionStorage(data);
  });
});
