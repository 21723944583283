window.timeToLoadBlocks = function () {
  let value = 0;
  if (!$('#timer-load-blocks').length) {
    $('html, body').animate({ scrollTop: $(document).height() }, 5000);

    const myVar = setInterval(myTimer, 10);

    function myTimer() {
      value = value + 10;
      $('#timer-load-blocks').find('.value').text(value);
      if (!$('.block.js-pending, .block.js-loading, .js-webpack-lazy-block').length) {
        myStopFunction();
      }
    }

    function myStopFunction() {
      clearInterval(myVar);
      console.log(value);
    }
  }
};
