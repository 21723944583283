$(function () {
  let agency_user_name = $.cookie('agency_user_web_name');
  let $header_mobile_menu = $('.header-mobile-menu');
  let $name = $header_mobile_menu.find('.name');

  if (typeof agency_user_name !== 'undefined' && agency_user_name.length > 0) {
    $('.top-nav').addClass('logged');
    $('.link-my-iberostar-pro').css('display', 'flex');
    $name.text(agency_user_name);
    $('.header-mobile-menu.logout').hide();
  }
});
